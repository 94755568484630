:root {
  --offwhite: #e1e1e1;
}

body {
  background-color: var(--offwhite);
}

.drop_downs {
  margin: 10px 0;
}

.submit_button {
  margin-top: 20px;
}

.landing_page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 95vh;
}

@media only screen and (max-width: 500px) {
  .landing_page {
    height: 85vh;
    margin-left: 10%;
    width: 80%;
  }
}

.navbar {
  margin: -31px -8px 5px -8px;
  background-color: #135413;
}

.nav_header {
  color: var(--offwhite);
  font-size: large;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  cursor: pointer;
}

.no_hover_header {
  color: var(--offwhite);
  font-size: large;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.menu_link {
  text-decoration: none;
  padding: 8px 15px;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.menu_link:hover {
  background-color: var(--offwhite);
}

.skinny_stack {
  display: inline;
  margin-top: 15px;
}

@media only screen and (min-width: 400px) {

  .nav_header,
  .no_hover_header {
    font-size: x-large;
    font-weight: unset;
  }

  .navbar {
    margin: -38px -8px 5px -8px;
    padding-bottom: 6px;
  }

  .navbar_icon {
    margin-top: 4px;
  }

  .skinny_stack {
    display: flex;
    margin-top: unset;
  }
}

.thin_text_reduce {
  display: inline;
}

.desktop_text {
  display: none;
}

@media only screen and (min-width: 350px) {
  .thin_text_reduce {
    display: none;
  }

  .desktop_text {
    display: inline;
  }
}

.no_mobile_text {
  display: inline;
}

@media only screen and (max-width: 450px) {
  .no_mobile_text {
    display: none;
  }
}

.navbar_icons {
  text-align: right;
  padding-top: 3px;
}

.nav_icon {
  margin: 0 5px;
  padding: 2px;
}

.action_icon {
  margin: 0 5px;
  padding: 2px;
  display: flex;
}

.route_name {
  padding: 2px;
}

.assignment_icon:hover,
.route_icon:hover,
.navbar_icon:hover,
.action_icon:hover,
.nav_header:hover,
.route_name:hover,
.graph_card:hover {
  cursor: pointer;
}

@media only screen and (min-width: 900px) {

  .assignment_icon:hover,
  .route_icon:hover,
  .navbar_icon:hover,
  .action_icon:hover,
  .nav_header:hover {
    box-shadow: 0 8px 16px 0 black;
    border-radius: 5px;
  }

  .route_name:hover,
  .graph_card:hover {
    box-shadow: 0 8px 16px 0 gray;
    background-color: #fafafa;
    border-radius: 10px;
  }
}

.route_cards {
  display: grid;
  grid-template-columns: repeat(auto-fit,
      minmax(min(100%/1, max(350px, 100%/6)), 1fr));
  gap: 6px;
  margin-top: 15px;
}

.route_card,
.graph_card {
  border: solid;
  border-width: 3px;
  padding: 3px;
  box-shadow: 2px 2px gray;
  border-radius: 10px;
}

.route_cards_print {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.graph_card_print {
  border: solid;
  width: 18.9rem;
  margin: 5px 5px 0 0;
  padding: 3px;
  box-shadow: 2px 2px gray;
  border-radius: 10px;
}

.page_break {
  page-break-before: always;
  margin-bottom: 5px;
}

.chosen_route_card {
  border: solid;
  border-width: 3px;
  padding: 2px 3px 3px 3px;
  box-shadow: 2px 2px gray;
  border-radius: 10px;
}

.chosen_route_columns {
  column-width: 350px;
  column-gap: 20px;
  column-count: 5;
  margin-top: 15px;
}

.route_icon,
.assignment_card {
  margin-top: 10px;
  break-inside: avoid-column;

}

.assignment_icon {
  margin-left: 10px;
  padding: 2px;
}

.graph {
  margin: 20px 0 40px 0;
}

.graph_border {
  margin: 0 auto;
  max-width: 1000px;
  border-radius: 10px;
  border: solid;
  padding: 3px;
}

.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
}

.modal {
  position: fixed;
  z-index: 3;
  background-color: var(--offwhite);
  border-radius: 20px;
  border: solid;
  border-width: 5px;
  padding: 10px;
  height: fit-content;
  max-height: 75vh;
  width: 80vw;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.info_modal {
  position: fixed;
  z-index: 3;
  background-color: var(--offwhite);
  border-radius: 20px;
  border: solid;
  border-width: 5px;
  padding: 10px;
  max-height: 75vh;
  width: 80vw;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 550px) {
  .modal {
    width: 425px;
  }

  .info_modal {
    width: 425px;
    padding: 30px;
  }
}

.modal::-webkit-scrollbar,
.info_modal::-webkit-scrollbar {
  display: none;
}

.graph_modal {
  position: fixed;
  z-index: 3;
  background-color: var(--offwhite);
  border-radius: 20px;
  border: solid;
  border-width: 5px;
  padding: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85vw;
  height: calc(42.5vw + 76px);
  max-height: 85vh;
}

.graph_center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chosen_graph_center {
  height: calc(100% - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload_box {
  cursor: pointer;
  padding: 20px;
  border-style: dashed;
  border-width: 2px;
  border-radius: 10px;
  display: block;
  z-index: 3;
}

.upload_box_show_info {
  padding: 22px;
  display: block;
}

.show_info {
  background: var(--offwhite);
  position: absolute;
  margin: 10px 30px 0 20px;
  padding: 5px 20px;
  border-radius: 10px;
  border: solid;
  border-width: 3px;
  border-color: #135413;
  z-index: 4;
}